import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router';

import { Palette } from 'core/config';

import Navbar from './Navbar';
import Footer from './Footer';

type Props = React.HTMLAttributes<HTMLDivElement>;

export const MainLayout: React.FunctionComponent<Props> = ({ children }) => {
  const { pathname } = useLocation();

  return (
    <>
      <Navbar />
      <Container>
        {children}
      </Container>
      {pathname !== '/' ? <Footer /> : null}
    </>
  );
};

const Container = styled.main`
  z-index: 0;
  width: 100%;
  min-height: 100vh;
  background-color: ${Palette.BLACK};
`;
