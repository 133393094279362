import React from 'react';
import Captcha from 'react-google-recaptcha';
import styled from 'styled-components';

import { RECAPTHA_KEY } from 'core/utilities';
import { useIsDesktop } from 'view/hooks';

type ReCAPTCHAProps = {
  recaptchaRef: React.Ref<Captcha> | undefined;
  onChange: ((token: string | null) => void) | undefined;
}

export const ReCAPTCHA: React.FunctionComponent<ReCAPTCHAProps> = ({ recaptchaRef, onChange }) => {
  const [ isLargeScreen ] = useIsDesktop();
 
  if(isLargeScreen) return (
    <ReCAPTCHAWrapper
      ref={recaptchaRef}
      // size="invisible"
      theme="dark"
      onChange={onChange}
      sitekey={RECAPTHA_KEY}
    />
  );

  return (
    <ReCAPTCHAWrapper
      ref={recaptchaRef}
      // size="invisible"
      size="compact"
      theme="dark"
      onChange={onChange}
      sitekey={RECAPTHA_KEY}
    />
  );
};

const ReCAPTCHAWrapper = styled(Captcha)`
  margin: 20px auto 32px;
`;