import React from 'react';
import { Helmet } from 'react-helmet-async';

interface Props {
  url?: string;
  img?: string;
  title: string;
  links?: Array<{ href: string; rel: 'preload'; as: 'audio' | 'image'; }>;
  description: string;
  preventIndex?: boolean;
  renderTrackMeta?: boolean;
}

export const CanonicalTag: React.FunctionComponent<Props> = ({ title, description, url, img, renderTrackMeta, preventIndex, links }) => {
  const metaUrl = url ?? `https://callasto.com/${title.toLowerCase()}`;
  const metaImg = img ?? 'https://cdn.callasto.com/artwork/callasto_logo.jpeg';
  const renderTitle = renderTrackMeta ? title : `Callasto | ${title}`

  return (
    <Helmet>
        <title>{renderTitle}</title>
        {links?.length 
          ? links.map(({ href, as, rel = 'preload' }, i) => <link key={`preload-link_${i}`} rel={rel} as={as} href={href} /> ) 
          : null
        }
        <meta name="robots" content={preventIndex ? "noindex, nofollow" : "index, follow"} />
        <meta name="description" content={description} />
        <link rel='canonical' href={metaUrl} />

        <meta name="twitter:title" content={renderTitle} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={metaImg} />
        <meta name="twitter:image:alt" content={renderTitle} />
        
        <meta property="og:title" content={renderTitle} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={metaUrl} />
        <meta property="og:image" content={metaImg} />
        <meta property="og:image:secure_url" content={metaImg} />
        <meta property="og:image:alt" content={renderTitle} />

    </Helmet>
  );
};
