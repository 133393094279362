export const NODE_ENV = process.env.REACT_APP_NODE_ENV;
export const DEFAULT_ERROR_MESSAGE = 'There was an error in submitting your request. Please try again.';
export const RECAPTHA_KEY = process.env.REACT_APP_RECAPTHA_KEY ?? '';
export const BACKEND_BASE_URI = 'https://api.callasto.com';
export const CDN_BASE_URI = 'https://cdn.callasto.com';
export const FETCH_TIME_DURATION = 8000; //8 seconds

/* eslint-disable */
export const noop = () => { };

export const MOBILE = {
    window: {
        maxWidth: 766
    }
};