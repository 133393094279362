import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Palette } from 'core/config';

import { ReactComponent as CC } from 'assets/icons/cc.svg';

interface Props {
  isFullHeight?: boolean;
}

const Footer: React.FunctionComponent<Props> = ({ isFullHeight }) => {
  const [loaded, setLoaded] = useState<boolean>(false);

  const currentYear = new Date().getFullYear(); 

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <Container loaded={loaded} isFullHeight={isFullHeight}>
      <div><CreativeCommons width='13px' height='13px' title='Create Commons icon'/> {currentYear} callasto.com</div>
    </Container>
  );
};

const Container = styled.footer<{ isFullHeight?: boolean; loaded: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${({ loaded }) => (loaded ? 1 : 0)};
  transition: opacity 0.5s ease-in;

  height: 7vh;
  color: ${Palette.WHITE};
  text-align: center;
`;

const CreativeCommons = styled(CC)`
    width: 13px;
    fill: ${Palette.RED};
    height: auto;
`;

export default Footer;
