export enum Palette {
  WHITE = '#FFF',
  BLUE = 'blue',
  BLACK = '#000',
  RED = '#e50914',
  COD_GREY = '#111111',
  PACIFIC_BLUE = '#00AABB',
  DARK_GRAY = '#010101',
  CYAN_BLUE = '#1D77FF',
  TURQUOISE = '#56DDD2'
}