import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { Palette } from 'core/config';

interface Props {
  isSubmitting: boolean;
  name:         string;
  value:        string;
  focused:      boolean;
  checked:      boolean;
  children:     React.ReactNode;
  onKeyPress:   React.KeyboardEventHandler<HTMLInputElement> | undefined
  onFocus:      React.FocusEventHandler<HTMLInputElement> | undefined
  onBlur:       React.FocusEventHandler<HTMLInputElement> | undefined
  onChange:     React.ChangeEventHandler<HTMLInputElement> | undefined
}

export const Checkbox: React.FunctionComponent<Props> = (
  { 
    name,
    value,
    focused, 
    checked, 
    children,
    isSubmitting,
    onKeyPress,
    onChange,
    onFocus,
    onBlur
  }) => {
  return (
    <CheckBoxContainer margin>
      <CheckboxInput
        type="checkbox"
        id={`${name}__checkbox`}
        name={name}
        value={value}
        checked={checked} 
        disabled={isSubmitting}
        onKeyDown={onKeyPress}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <Label htmlFor={`${name}__checkbox`}>
        <CheckedAnimation active={checked} focused={focused} />{children}
      </Label>
    </CheckBoxContainer>
  );
};

const pop = keyframes`
  0% { opacity: 0; }
  75% { opacity: 1; }
  100% { transform: scale(1.2); }
`;

export const CheckboxInput = styled.input<{ active?: boolean; }>`
  -webkit-appearance: none;
  appearance: none;
`;

export const CheckBoxContainer = styled.div<{ margin?: boolean; active?: boolean; inheritHeight?: boolean; }>`
  display: flex;
  ${({ margin }) => margin ? 'margin-bottom: 7px; margin-left: 7px;' : ''}
  :nth-last-child(2) {
    margin-bottom: 0;
  }
`;

export const CheckedAnimation = styled.span<{ active?: boolean; focused?: boolean; }>`
  display: inline-block;
  width: 17px;
  height: 13px;
  cursor: pointer;

  :before,
  :after {
    content: "";
    position: absolute;
    z-index: 1;
    width: 15px;
    height: 15px;
    transition: all .3s ease-in-out .1s;
    background: transparent;
    border: 2px solid ${Palette.RED}; 
    border-radius: 2px;
  }
  :after {
    z-index: 0;
    border: none;
  }

  ${({ active }) => active ? css`
    :before {
      animation: ${pop} .3s ease .1s;
      z-index: 10;
      background: ${Palette.RED};
      border-radius: 2px;
    } 
  ` : null}

  ${({ focused }) => focused ? css`
    :before {
      border: 2px solid white; 
    }
  ` : null}
`;

const Label = styled.label<{ margin?: boolean; required?: boolean; requiredAbsolute?: boolean; marginAuto?: boolean; marginLeft?: boolean; }>`
  position: relative;
  text-align: start;
  color: ${Palette.WHITE};
  font-size: 16px;

  > span {
    font-size: 12px;
  }
`;