import {
  BACKEND_BASE_URI,
  Data,
  DEFAULT_ERROR_MESSAGE
} from 'core/utilities';

export enum HTTPMethod {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
  PATCH = 'PATCH',
  HEAD = 'HEAD',
}

export interface Result {
  success: boolean;
  reply?: Data;
  error?: string;
}

export enum Route {
  SUBSCRIBE = 'subscribe',
  CONTACT = 'contact'
}

export const query = async (route: Route, headers: Data = {}): Promise<Result> => {
  const options = {
    method: HTTPMethod.GET,
    headers: {
      'Content-Type': 'application/json',
      ...headers
    }
  };

  try {
    const response = await fetch(`${BACKEND_BASE_URI}/${route}`, options);
    const reply = await response.json();

    return {
      success: true,
      reply
    };
  }
  catch (error) {
    return {
      success: false,
      error: DEFAULT_ERROR_MESSAGE
    };
  }
};

export const mutate = async (route: Route, payload: Data = {}, headers: Data = {}, method: HTTPMethod = HTTPMethod.POST): Promise<Result> => {
  const options = {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...headers
    },
    body: JSON.stringify(payload)
  };

  try {
    const response = await fetch(`${BACKEND_BASE_URI}/${route}`, options);
    const reply = await response.json();

    return {
      success: response.status === 200,
      reply
    };
  }
  catch (error) {
    return {
      success: false,
      error: DEFAULT_ERROR_MESSAGE
    };
  }
};