export const DeviceSize = {
  mobileS  : 322,
  mobileM  : 374,
  mobileL  : 410,
  tablet   : 767,
  laptopS  : 1024,
  laptopM  : 1366,
  laptopL  : 1420,
  desktopS : 1600,
  desktopM : 1920,
  desktopL : 2560
};

export const MediaQuery = {
  mobileS  : `(min-width: ${DeviceSize.mobileS}px)`,
  mobileM  : `(min-width: ${DeviceSize.mobileM}px)`,
  mobileL  : `(min-width: ${DeviceSize.mobileL}px)`,
  tablet   : `(min-width: ${DeviceSize.tablet}px)`,
  laptopS  : `(min-width: ${DeviceSize.laptopS}px)`,
  laptopL  : `(min-width: ${DeviceSize.laptopL}px)`,
  laptopM  : `(min-width: ${DeviceSize.laptopM}px)`,
  desktopS : `(min-width: ${DeviceSize.desktopS}px)`,
  desktopM : `(min-width: ${DeviceSize.desktopM}px)`,
  desktopL : `(min-width: ${DeviceSize.desktopL}px)`
};