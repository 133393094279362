import React, { useState, useRef, useEffect } from 'react';

import { useIntersectionObserver } from 'view/hooks';
import { ImageWrapper } from 'view/components/common';
import styled from 'styled-components';

type Props = {
  src: string;
  fallbackSrc: string;
  placeholder: string;
  fallbackPlaceholder: string;
  alt: string;
  width?: string;
  height?: string;
}

export const RenderImage: React.FunctionComponent<Props> = React.memo(({ src, alt, placeholder, fallbackSrc, fallbackPlaceholder, width = '75px', height = '75px' }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isInView, setIsInView] = useState(false);
  const imgRef = useRef<any>();
  const entry = useIntersectionObserver(imgRef, {});

  useEffect(() => {
    if(!entry?.isIntersecting) return;
    setIsInView(true);
  },[ entry?.isIntersecting]);

  const handleOnLoad = () => {
    setIsLoaded(true);
  };

  return (
    <ImageContainer ref={imgRef} >
      {isInView && (
        <React.Fragment>
          {/* Swapping image groups below defines z-index of images */}
          <ImageWrapper src={src}>
            <Image src={fallbackSrc} alt={alt} isLoaded={isLoaded} onLoad={handleOnLoad} width={width} height={height} />
          </ImageWrapper>
          <ImageWrapper src={placeholder}>
            <Image src={fallbackPlaceholder} alt={alt} isLoaded={!isLoaded} width={width} height={height}/>
          </ImageWrapper>
        </React.Fragment>
      )}
    </ImageContainer>
  );
});

const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  min-height: 75px;
  min-width: 75px;
  margin: 15px 0;
  border-radius: 5px;
`;

const Image = styled.img<{ isLoaded: boolean; }>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: opacity 1s ease-in-out;
  opacity: ${({ isLoaded}) => isLoaded ? 1 : 0};
  border-radius: 5px;
  :hover {
    opacity: ${({ isLoaded }) => isLoaded ? 0.3 : 0.9};
  }
`;