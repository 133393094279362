import { RefObject, useEffect, useState } from 'react';

interface Props extends IntersectionObserverInit {
  freezeOnceVisible? : boolean;
}

export const useIntersectionObserver = (
  elementRef: RefObject<Element>, 
  {
      threshold         = 0,
      root              = null,
      rootMargin        = '0%',
      freezeOnceVisible = false
  } : Props
) : IntersectionObserverEntry | undefined => {
  const [ entry, setEntry ] = useState<IntersectionObserverEntry>();
  const frozen = entry?.isIntersecting && freezeOnceVisible;

  const updateEntry = ([ entry ] : Array<IntersectionObserverEntry>) : void => {
    setEntry(entry);
  };

  useEffect(() => {
    const node = elementRef?.current;
    const isSupported = !!window.IntersectionObserver;

    if (!isSupported || frozen || !node) return;

    const observerParameters = { threshold, root, rootMargin };
    const observer = new IntersectionObserver(updateEntry, observerParameters);

    observer.observe(node);

    return () => observer.disconnect();

  }, [ elementRef, threshold, root, rootMargin, frozen ]);

  return entry;
};