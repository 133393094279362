import React from 'react';
import { CDN_BASE_URI, FETCH_TIME_DURATION } from 'core/utilities';
import { AudioFormat } from 'view/pages/Releases/trackData';


export const fetchButtonFocus = (ref: React.MutableRefObject<HTMLDivElement | null>, dataId: string, dataValue: string | number) => {
  return ref.current
    ? ref.current.querySelectorAll(`[data-${dataId}="${dataValue}"]`)[0]
    : null;
};

const downloadBlob = (blobUrl: string, filename: string) => {
  let a = document.createElement('a');
  a.download = filename;
  a.href = blobUrl;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(a.href);
  a.remove();
  return;
};

const downloadTime = (currentChunk: number, amountOfChunks: number, startTime: number) => {
  const elapsedTime = (new Date().getTime()) - startTime;
  const chunksPerTime = currentChunk / elapsedTime;
  const estimatedTotalTime = amountOfChunks / chunksPerTime;
  const timeLeftInSeconds = (estimatedTotalTime - elapsedTime) / 1000;
  // var withOneDecimalPlace = Math.round(timeLeftInSeconds * 10) / 10;
  return timeLeftInSeconds;
};

const watchProgress = async (
  response: Response,
  progressCallback: (percentage: number) => void,
  getDownloadTime?: (time: number, receivedLength: string, contentLength: string) => void

) => {
  if (!response.body || !response.headers) return;
  const reader = response.body.getReader();

  const startTime = new Date().getTime();
  const contentLength = Number(response.headers.get('Content-Length')) || 0;

  let receivedLength = 0;

  // Process the data
  const processChunk = async (value: Uint8Array | undefined) => {
    // Add to the received length
    receivedLength += value?.length || 0;
    const percentage = Math.round((receivedLength / contentLength) * 100);
    progressCallback(percentage);
    if (getDownloadTime) {
      const time = downloadTime(receivedLength, contentLength, startTime);
      const receivedLengthBytes = niceBytes(receivedLength.toString());
      const contentLengthBytes = niceBytes(contentLength.toString());
      getDownloadTime(time, receivedLengthBytes, contentLengthBytes);
    }
  }

  const read = async () => {
    let data;
    try {
      data = await reader.read();
    } catch {
      // Ignore errors because we can only handle the main response
      return;
    };

    // Continue reading data until done
    if (!data.done) {
      processChunk(data.value);
      await read();
    };
  };

  read();
};

export const downloadResource = async (
  url: string,
  fileName?: string,
  abortController?: AbortController,
  progressCallback?: (progress: number) => void,
  getDownloadTime?: (time: number, receivedLength: string, contentLength: string) => void,
  timeout: number = FETCH_TIME_DURATION,
) => {
  if (!url) return;
  if (url.length === 0) return;

  const filename = fileName ? fileName : url.split('\\').pop()?.split('/').pop();

  const audioFileName = filename || 'callasto music';

  const signal = abortController?.signal;

  const timeoutMessage = `Download cancelled: timed out at ${timeout / 1000} seconds. Check your network connection`;
  let id = setTimeout(() => abortController?.abort(timeoutMessage), timeout);

  try {
    const response = await fetch(url, { signal });

    clearTimeout(id);

    if (!response.ok) {
      throw new Error(`Failed to fetch ${url}: ${response.status} (${response.statusText})`)
    }

    // Read the data to track progress
    if (progressCallback) {
      await watchProgress(response.clone(), progressCallback, getDownloadTime);
    }

    const contentType = response.headers.get('Content-Type') ?? 'audio/mp3';
    const blob = await response.blob();
    const newBlob = new Blob([blob], { type: contentType });

    let blobUrl = window.URL.createObjectURL(newBlob);
    return downloadBlob(blobUrl, audioFileName);
  }
  catch (error) {
    //@ts-ignore
    if (signal?.aborted && signal.reason) {
      //@ts-ignore
      throw new Error(signal.reason);
    } else throw error;
  } finally {
    if (id) {
      clearTimeout(id);
    }
  };
}

export const isEmpty = (obj: { [key: string]: any }) =>
  Object.keys(obj).length === 0;

export const getFileExt = (path: string) => path.split('.').pop();

export const isValidEmail = (value: string): boolean => {
  const trimVal = value.trim();
  const validLength = trimVal.length >= 4 && trimVal.length <= 254;
  const regex = new RegExp(/\S+@\S+\.\S+/);

  return !!trimVal && validLength && regex.test(trimVal);
};

export const isValidUsername = (value: string): boolean => {

  const trimVal = value.trim();
  const validLength = trimVal.length >= 1 && trimVal.length <= 254;
  const regex = new RegExp(/^[a-zA-Z0-9_.-]+$/);

  return !!trimVal && validLength && regex.test(trimVal);
};

export const formatAudioSrc = (src: string, mp3: boolean, opp?: boolean, hqAudioFormat?: AudioFormat) => mp3
  ? `${CDN_BASE_URI}/music/mp3/${src}.mp3`
  : opp
    ? `${CDN_BASE_URI}/music/ogg/${src}.ogg`
    : `${CDN_BASE_URI}/music/hq/${src}.${hqAudioFormat}`;

export const formatArtworkSrc = (src: string, fallback?: boolean, dest = 'artwork', svg?: boolean,) =>
  svg
    ? `${CDN_BASE_URI}/${dest}/${src}.svg`
    : fallback
      ? `${CDN_BASE_URI}/${dest}/${src}.jpeg`
      : `${CDN_BASE_URI}/${dest}/${src}.webp`;

export const validateTimeRange = (date: number, ms: number) => {
  const range = Date.now() - ms;
  return date > range;
};


export const niceBytes = (val: string) => {
  const units = ['bytes', 'kb', 'mb', 'gb', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

  let l = 0, n = parseInt(val, 10) || 0;

  while (n >= 1024 && ++l) {
    n = n / 1024;
  }

  return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + units[l]);
};

export const clampInt = (num: number, min: number = 0, max: number = 100) => Math.min(Math.max(num, min), max);