import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { AppRouter } from 'core/router';

import ToastProvider from 'view/components/common/Toast/ToastProvider';

function App() {
  return (
    <HelmetProvider>
      <ToastProvider>
        <AppRouter />
      </ToastProvider>
    </HelmetProvider>
  );
}

export default App;
