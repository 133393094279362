import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route as ReactRoute } from 'react-router-dom';
import Route, { RouteProps, RouteAccess } from './Route';
import { LoadingSpinner } from 'view/components/common';
import { MainLayout } from 'view/components/layout';

const ReleasesPage = lazy(() =>  import(/* webpackChunkName: 'ReleasesPage' */ /* webpackPrefetch: true */ 'view/pages/Releases'));
const HomePage = lazy(() => import(/* webpackChunkName: 'HomePage' */ /* webpackPrefetch: true */ 'view/pages/Home'));
const BioPage = lazy(() => import(/* webpackChunkName: 'BioPage' */ /* webpackPrefetch: true */ 'view/pages/Bio'));
const ConfirmationPage = lazy(() => import(/* webpackChunkName: 'ConfirmationPage' */'view/pages/Confirmation'));
const NewsletterPage = lazy(() => import(/* webpackChunkName: 'NewsletterPage' */ 'view/pages/Newsletter'));
const ContactPage = lazy(() => import(/* webpackChunkName: 'NewsletterPage' */ 'view/pages/Contact'));
const NotFoundPage = lazy(() => import(/* webpackChunkName: 'NotFoundPage' */ 'view/pages/NotFound'));
const FYIPage = lazy(() => import(/* webpackChunkName: 'FYIPage' */ 'view/pages/FYI'));

export enum RoutePath {
  CONFIRMATION_ID = '/confirmation/:id',
  CONFIRMATION = '/confirmation',
  NOT_FOUND = '/page-not-found',
  RELEASES_ID = '/releases/:id',
  NEWSLETTER = '/newsletter',
  LINKS = '/detect-networks',
  RELEASES = '/releases',
  CONTACT = '/contact',
  BIO = '/bio',
  FYI = '/fyi',
  ROOT = '/',
}

const routes: Array<RouteProps> = [
    {
      path: RoutePath.ROOT,
      access: RouteAccess.public,
      layout: MainLayout,
      component: HomePage
    },
    {
      path: RoutePath.RELEASES,
      access: RouteAccess.public,
      layout: MainLayout,
      component: ReleasesPage
    },
    {
      path: RoutePath.RELEASES_ID,
      access: RouteAccess.public,
      layout: MainLayout,
      component: ReleasesPage
    },
    {
      path: RoutePath.CONFIRMATION,
      access: RouteAccess.public,
      layout: MainLayout,
      component: ConfirmationPage
    },
    {
      path: RoutePath.CONFIRMATION_ID,
      access: RouteAccess.public,
      layout: MainLayout,
      component: ConfirmationPage
    },
    {
      path: RoutePath.NEWSLETTER,
      access: RouteAccess.public,
      layout: MainLayout,
      component: NewsletterPage
    },
    {
      path: RoutePath.CONTACT,
      access: RouteAccess.public,
      layout: MainLayout,
      component: ContactPage
    },
    {
      path: RoutePath.BIO,
      access: RouteAccess.public,
      layout: MainLayout,
      component: BioPage
    },
    {
      path: RoutePath.FYI,
      access: RouteAccess.public,
      layout: MainLayout,
      component: FYIPage
    },
    {
      path: RoutePath.LINKS,
      access: RouteAccess.public,
      layout: MainLayout,
      component: HomePage
    },
];

const AppRouter: React.FunctionComponent = () => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <BrowserRouter>
        <Routes>
          {routes.map(({ path, access, component, ...props }: RouteProps) => {
            return (
              <ReactRoute
                // index
                key={path}
                path={path}
                element={
                  <Route
                    path={path}
                    access={access}
                    component={component}
                    {...props}
                  />
                }
              />
            );
          })}
          <ReactRoute path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default AppRouter;