import React from 'react';
// import { Route as ReactRoute } from 'react-router-dom'
import { useAuthorization } from 'core/context';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';

export enum RouteAccess {
    public    = 'public',
    private   = 'private'
}

export interface RouteProps {
    exact?            : boolean;
    path              : string;
    access            : RouteAccess;
    component?        : React.ComponentType;
    privateComponent? : React.ComponentType;
    publicComponent?  : React.ComponentType;
    layout?           : React.ComponentType;
}

const Route : React.FunctionComponent<RouteProps> = ({ access, component, ...props }) => {
  const { isAuth } = useAuthorization();
  
  switch (access) {
    case RouteAccess.public:
      return <PublicRoute component={component} {...props} isAuth={isAuth} />;
    case RouteAccess.private:
      return <PrivateRoute component={component} {...props} isAuth={isAuth} />;
  }
};

export default React.memo(Route);