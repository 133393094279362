import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { MOBILE } from 'core/utilities';

export const useIsDesktop = (): [ boolean, Dispatch<SetStateAction<boolean>> ] => {
  const [ isDesktop, setIsDesktop ] = useState<boolean>(window.innerWidth > MOBILE.window.maxWidth);

  useEffect(() => {
    setIsDesktop(window.innerWidth > MOBILE.window.maxWidth);

    const handleResize = () => {
      setIsDesktop(window.innerWidth > MOBILE.window.maxWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return [ isDesktop, setIsDesktop ];
};