import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

import { useLockedBody } from 'view/hooks';
import { Palette } from 'core/config';
import { ReactPortal } from '.'

export enum ModalStatus {
  DOWNLOADING = 'downloading',
  CANCELLED   = 'cancelled',
  COMPLETE    = 'complete',
  PENDING     = 'pending',
  SUCCESS     = 'success',
  ERROR       = 'error'
}

type ModalProps = {
  show: boolean;
  status?: ModalStatus | null;
  children: React.ReactNode;
  ariaDescribedBy?: string;
  disabled?: boolean;
  onClose: () => void;
  title?: string;
  css?: string;
};


export const Modal: React.FunctionComponent<ModalProps> = ({
  show,
  status,
  children,
  ariaDescribedBy,
  onClose,
  title,
  css,
}) => {
  const [locked, setLocked] = useLockedBody(false, 'root')
  const modalRef = useRef<HTMLDivElement | null>(null);
  const isMounted = useRef<boolean>(false);
  
  useEffect(() => {
    
    if(show) {
      setLocked(true);
    }
      
    const modalElement = modalRef.current;

    //add any focusable HTML element you want to include to this string
    const focusableElements = modalElement ? modalElement.querySelectorAll(
      'button:not([aria-disabled="true"]), [href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"])'
    ) : [];

    const lastElement = focusableElements[focusableElements.length - 1];
    const firstElement = focusableElements[0];

    if(show && !isMounted.current) {
      //@ts-ignore
      firstElement?.focus();
      isMounted.current = true
    }

    const handleTabKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Tab") {
        if (event.shiftKey && document.activeElement === firstElement) {
          event.preventDefault();
          // @ts-ignore
          lastElement.focus();
        } else if (
          !event.shiftKey &&
          document.activeElement === lastElement
        ) {
          event.preventDefault();
          // @ts-ignore
          firstElement.focus();
        }
      }
    };

    const close = (e: KeyboardEvent) => {
      if (show && e.code === 'Escape') {
        isMounted.current = false;
        setLocked(!locked);
        onClose();
      }
    };

    const handleOutsideClick = (e: MouseEvent) => {
      if (
        show &&
        modalRef.current &&
        !modalRef.current.contains(e.target as Node)
      ) {
        isMounted.current = false;
        setLocked(!locked);
        onClose();
      }
    };

    if(!show) {
      isMounted.current = false;
      setLocked(false);
    }

    window.addEventListener('keydown', close);
    window.addEventListener('keydown', handleTabKeyPress);
    document.addEventListener('mousedown', handleOutsideClick, false);

    return () => {
      window.removeEventListener('keydown', close);
      window.removeEventListener('keydown', handleTabKeyPress);
      document.removeEventListener('mousedown', handleOutsideClick, false);
    };
  }, [show, setLocked, locked, onClose, isMounted]);

  const handleClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    isMounted.current = false;
    e.stopPropagation();    
    setLocked(!locked);
    onClose();
  }

  const titleId = `modal-title_${title?.replace(/\s+/g, '-')}`;

  return (
    <ReactPortal>
      <Container visible={show}>
        <Background visible={show} />
        <ModalContainer 
          role="alertdialog" 
          aria-modal="true"
          aria-describedby={ariaDescribedBy}
          aria-labelledby={titleId}
          title={title} 
          visible={show} 
          ref={modalRef} 
          css={css}
        >
          {title !== undefined && (
            <Title>
              <h2 id={titleId}>{title}
                {status 
                  ? <Status status={status}>{status}</Status> 
                  : null
                }
              </h2>
              <CloseButton onClick={handleClose} tabIndex={0} aria-label="Exit modal">EXIT</CloseButton>
            </Title>
          )}
          <HR />
          <Content hasTitle={title !== undefined}> {children}</Content>
        </ModalContainer>
      </Container>
    </ReactPortal>
  );
};

const Container = styled.div<{ visible: boolean }>`
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: grid;
  place-items: center;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 400;
  transition: ${({ visible }) => !visible ? 'visibility 1ms ease-in-out 0.7s' : 'visibility 1ms ease-in-out'};
  visibility: 'hidden';

  ${({ visible }) =>
    visible ? css`
      visibility: 'visible';
      pointer-events: auto;
      transition: all 300ms ease-in-out;
    ` : ''}
`;

const Background = styled.div<{ visible: boolean }>`
  position: fixed;
  margin: 0 auto;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${Palette.BLACK};
  filter: blur(2px) brightness(93%) opacity(18%);
  pointer-events: none;
  opacity: 0;
  transition: opacity 250ms 700ms ease;
  ${({ visible }) =>
    visible ? css`
      pointer-events: auto;
      opacity: 1;
      transition: all 300ms ease-in-out;
    ` : ''
  }
`;

const ModalContainer = styled.div<{ visible: boolean; css?: string }>`
  overflow-x: hidden;
  position: relative;
  display: block;
  width: 400px;
  max-width: 90vw;
  margin: auto;
  border-radius: 15px;
  background-color: rgba(0,0,0,0.90);
  color: ${Palette.WHITE};
  align-self: center;
  box-shadow: 0 12px 25px 0 rgba(229, 9, 20, 0.25);
  opacity: 0;
  transition: opacity 250ms 250ms ease, transform 300ms 250ms ease;
  transform: scale(0);
  ${({ css }) => css && css};
  ${({ visible }) =>
    visible ? css`
      opacity: 1;
      transform: scale(1);
      transition: opacity 250ms 500ms ease, transform 350ms 500ms ease;
    ` : ''
    }
`;

const Content = styled.div<{ hasTitle: boolean }>`
  text-align: center;
  ${({ hasTitle }) =>
    hasTitle
      ? css`
          padding: 10px 24px 32px 24px;
        `
      : css`
          padding: 15px 18px 32px;

        `
  };
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 24px;
  width: 100%;
  color: ${Palette.WHITE};

  h2 { 
    font-size: 16px; 
  }
`;

const HR = styled.hr`
  border: 1px solid ${Palette.WHITE};
  border-radius: 5px;
  margin: 6px 24px;
`;

const Status = styled.span<{ status: string; }>`
  color: ${({ status }) => 
    (status === ModalStatus.PENDING || status === ModalStatus.DOWNLOADING)
    ? '#1aa7ec'
    : (status === ModalStatus.ERROR || status === ModalStatus.CANCELLED)
    ? '#e63b60'
    : '#4ADEDE'
  };
  
  :before {
    content: " ( ";
    color: ${Palette.WHITE};
  }
  :after {
    content: " )";
    color: ${Palette.WHITE};
  }
`;

const CloseButton = styled.button`
    border: 1.5px solid ${Palette.WHITE};
    background-color: transparent;
    border-radius: 3px;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 3px;
    color: ${Palette.WHITE};
    padding: 5px;
    margin: auto 0;
    transition: all .3s ease-in-out .1s;
    cursor: pointer;

    :hover {
      background-color: ${Palette.RED};
    }
`;