import React from 'react';
import styled from 'styled-components';

interface Props {
    src           : string;
    children      : React.ReactNode;
    cssOverride?  : string;
    hasMobileSrc? : boolean;
}

export const ImageWrapper : React.FunctionComponent<Props> = ({ src, children, cssOverride }) => {
  return (
    <Picture css={cssOverride}>
      <source srcSet={src} type='image/webp' />
      {children}
    </Picture>
  );
};

const Picture = styled.picture<{ css?: string }>`
  ${({ css }) => css ?? ''}
`;