import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Palette } from "core/config";

type TooltipProps = {
  children: React.ReactNode;
  direction?: string;
  disabled: boolean;
  content: string;
  delay?: number;
}
export const Tooltip: React.FunctionComponent<TooltipProps> = (
  { 
    disabled, 
    direction, 
    content, 
    delay, 
    children
  }) => {
  const [active, setActive] = useState(false);
  const timeout = useRef<any>(null)

  const showTip = () => {
    timeout.current = setTimeout(() => {
      setActive(true);
    }, delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout.current);
    setActive(false);
  };

  useEffect(() => {
    return () => clearTimeout(timeout.current);
  }, []);

  return (
    <Wrapper onMouseEnter={showTip} onMouseLeave={hideTip}>
      {children}
      {(active && disabled) ? (
        <Tip>
          {content}
        </Tip>
      ) : null}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
  margin: auto;
`;

const Tip = styled.div`
  position: absolute;
  width: 110px;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px;
  color: ${Palette.BLACK};
  background: ${Palette.WHITE};
  font-size: 10px;
  line-height: 1;
  z-index: 100; 

  left: calc(110%);
  top: 50%;
  transform: translateX(0) translateY(-50%);

  :before {
    content: " ";
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 6px;
    margin-left: calc(6px * -1);
    left: calc(6px * -1);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-right-color: ${Palette.WHITE};
  }
`;
