import React from 'react';
import styled from 'styled-components';

import { ReactComponent as Close } from 'assets/icons/close.svg';
import { Palette } from 'core/config';

export type ToastMessageType = 'success' | 'failure' | 'warning'

type ToastType = {
  message: string;
  type: ToastMessageType;
  autoClose?: boolean;
  onClose: () => void;
}

const Toast: React.FunctionComponent<ToastType> = ({ message = 'Toast', type = 'success', autoClose, onClose }) => {
  return (
    <Wrapper type={type} role="alert">
      <ToastMessage>{message}</ToastMessage>
      {!autoClose ? (
      <CloseButton onClick={onClose} aria-label="Close notification">
        <Close />
      </CloseButton >
      ) : null}
    </Wrapper>
  );
};


const Wrapper = styled.div<{ type: ToastMessageType }>`
  background-color: ${Palette.RED};
  padding: 1.1em;
  box-shadow: hsl(25 10% 10% / 10%) 0px 1px 3px 0px, hsl(25 10% 10% / 5%) 0px 1px 2px 0px;
  border-radius: 0.5em;
  position: relative;
  color: ${Palette.WHITE};
  opacity: 0.95;

  transition: transform 250ms, opacity 250ms,
  box-shadow 250ms ease-in-out;

  :hover {
    opacity: 1;
    box-shadow: hsl(25 10% 10% / 30%) 0px 1px 3px 0px, hsl(25 10% 10% / 15%) 0px 1px 2px 0px;
  }

  & + & {
    margin-top: 0.5em;
  }

`;

const ToastMessage = styled.div`
  text-align: center;
  font-weight: bold;
  ::selection {
    background: ${Palette.CYAN_BLUE}
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  line-height: 1;
  width: 1em;
  height: 1em;
  background: none;
  border: none;
  cursor: pointer;
  color: inherit;
  opacity: 0.85;
  > svg {
    height: 100%;
    width: 100%;
    fill: currentColor;
  }
`;

export default Toast;