import React, { useContext, useReducer } from 'react';
import { initialState, AuthReducer, AuthState, AuthAction } from './reducer';

export interface AppContextData {
  isDesktop: boolean | null,
  setIsDesktop: (isDesktop: boolean) => void,
  scrollbarWidth: number,
  navbarIsExpanded: boolean,
  setNavbarIsExpanded: (navbarIsExpanded: boolean) => void
}

const AuthStateContext    = React.createContext<AuthState>(initialState);
const AuthDispatchContext = React.createContext<React.Dispatch<AuthAction>>(() => null);

export const AppContext   = React.createContext<AppContextData>({ 
  isDesktop: null,
  setIsDesktop: () => null,
  scrollbarWidth: 0,
  navbarIsExpanded: false,
  setNavbarIsExpanded: () => null
});

export const useAuthorization = () : AuthState => {
  const context = useContext(AuthStateContext);
  return context;
};

export const useAuthorizationDispatch = () : React.Dispatch<AuthAction> => {
  const context = useContext(AuthDispatchContext);
  return context;
};

export const useAppContext = () : AppContextData => {
  const context = useContext(AppContext);
  return context;
};

export const AuthProvider: React.FunctionComponent = ({ children }: any) => {
  const [ user, dispatch ] = useReducer<React.Reducer<AuthState, AuthAction>>(AuthReducer, initialState);

  return (
    <AuthStateContext.Provider value={user}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};