import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Palette } from 'core/config';

export const LoadingSpinner : React.FunctionComponent = () => {
  
  const delay: number = 300;
  const [ready, setReady] = React.useState(delay === 0);
  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (!ready) {
      timeout = setTimeout(() => setReady(true), delay);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!ready) return null;
  
  return  (
      <Container>
          <Spinner><div></div><div></div><div></div><div></div></Spinner>
      </Container>
  )
};

const Container = styled.div`
    width: 100%;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${Palette.BLACK};
`;

const ldsEllipsis1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

const ldsEllipsis3 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;

const ldsEllipsis2 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
`;

const Spinner = styled.div`
  z-index: 1000;
  position: relative;
  width: 75px;
	height: 32px;
  > div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: ${Palette.WHITE};;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    :nth-child(1) {
      left: 8px;
      animation: ${ldsEllipsis1} 0.6s infinite;
    }
    :nth-child(2) {
      left: 8px;
      animation: ${ldsEllipsis2} 0.6s infinite;
    }
    :nth-child(3) {
      left: 32px;
      animation: ${ldsEllipsis2} 0.6s infinite;
    }
    :nth-child(4) {
      left: 56px;
      animation: ${ldsEllipsis3} 0.6s infinite;
    }
  }
`;