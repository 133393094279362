import React, { useEffect } from 'react';
import { 
    Navigate, 
    RouteProps,
} from 'react-router-dom';

interface PublicRouteProps extends RouteProps
{
    path              : string;
    isAuth?           : boolean;
    isRestricted?     : boolean;
    layout?           : React.ComponentType<RouteProps>;
    component?        : React.ComponentType<RouteProps>;
    privateComponent? : React.ComponentType<RouteProps>;
}

export const PublicRoute : React.FunctionComponent<PublicRouteProps> = ({ 
    path,
    isAuth, 
    isRestricted, 
    layout : Layout, 
    component : Component = () => null,
    privateComponent : PrivateComponent,
    ...rest 
}) => {
  
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [path]);

  return isAuth && !isRestricted 
    ? (
      PrivateComponent === undefined ? (
        <Navigate to="/" replace />
      ) : (
        Layout === undefined ? (
          <PrivateComponent {...rest} />
        ) : (
          <Layout>
            <PrivateComponent {...rest} />
          </Layout>
        )
      )
    ) : (
      Layout === undefined ? (
        <Component {...rest} />
      ) : (
        <Layout>
          <Component {...rest} />
        </Layout>
      )
    );
};